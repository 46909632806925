<template>
  <div class="company-contaner">
    <el-row class="device-list">
      <el-row type="flex" align="middle" justify="space-between">
        <p class="title">所属公司</p>
      </el-row>
    </el-row>
    <div class="show-box">
      <div
        class="item c3 f16"
        v-for="(item, index) in userCompMsg"
        :key="index"
      >
        <p style="width: 400px">{{ item.companyName }}</p>
        <p>{{ item.roleType == 1 ? "老板" : "员工" }}</p>
        <p style="color:blue;margin-left: 20px;width: 100px;" class="a" @click="changeShowId(item.id)">{{ item.id ==  showId? "已进入" : "进入" }}</p>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YoufujichuangCompany",

  data() {
    return {
      userCompMsg: {},
showId:""
      // 公司
      // allComps: [],
    };
  },
  computed:  {
  },

  mounted() {},

  created() {
    // this.getAllComp();
    //查询登录用户所属公司
    this.getUserComp();

    // //查询登录用户所属公司
    // this.getUserComp();
  },

  methods: {
      changeShowId(id){
      console.log(id, '===id');
      
          localStorage.setItem('COMPID', id)
          this.showId = localStorage.getItem('COMPID');
          this.userCompMsg = [...this.userCompMsg];
          console.log(this.showId);
          
      },
    //查询登录用户所属公司
    // getUserComp() {
    //   this.$API
    //     .getUserComp()
    //     .then((res) => {
    //       if (res.code == 1) {
    //         this.allComps = res.data;
    //       } else {
    //         this.$message.info(res.msg);
    //       }
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    //查询登录用户所属公司
    getUserComp() {
      this.$API
        .getUserComp()
        .then((res) => {
          if (res.code == 1) {
            this.userCompMsg = res.data;
            if(this.userCompMsg.length){
              if(localStorage.getItem('COMPID')){
                // localStorage.setItem('COMPID', this.userCompMsg[0].id)
                this.showId =  localStorage.getItem('COMPID');
              }else{
                  localStorage.setItem('COMPID', this.userCompMsg[0].id)
                  this.showId =  localStorage.getItem('COMPID');
              }
              
            }
          } else {
            this.$message.info(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>

.company-contaner {
.a{
  cursor: pointer;
}
  box-sizing: border-box;
  padding: 32px;
  .device-list {
    width: 100%;
    margin-top: 0px;
    border-bottom: 1px solid #eeeeee;
    .title {
      padding-bottom: 16px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
      border: none;
    }
  }
  .show-box {
    .item {
      padding-top: 20px;
      padding-bottom: 20px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #eeeeee;
    }
  }
  .contact-box {
    width: 624px;
    display: flex;
    justify-content: space-between;
    margin-top: 104px;
    margin-left: 176px;
    .item {
      display: flex;
      align-items: center;
      flex-direction: column;
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 10px;
        font-size: 16px;
        color: #333333;
        span {
          color: #007bd9;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
